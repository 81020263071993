import React, { useEffect,useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
  RemoveRedEye as RemoveRedEyeIcon,
} from "@mui/icons-material";
import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Typography,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  LinearProgress,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Checkbox,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Box, spacing, Stack } from "@mui/system";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@mui/system/Unstable_Grid";
import InputMask from "react-input-mask";
import CreateSignature from "../../createsignature/index"
import { LoadingButton } from "@mui/lab";

const Divider = styled(MuiDivider)(spacing);


function SignatureConfigTriggerCreate() {


let { getApi,postApi,ShowModalFunc,showModal,isInitializedBS } = useAuth();

const [suspend_after_mark, setSuspendAfterMark] = React.useState(1);
const [suspend_after_count, setSuspendAfterCount] = React.useState(1);
const [suspend_after_count_type, setSuspendAfterCountType] = React.useState(3);

const [cancel_after_mark, setCancelAfterMark] = React.useState(false);
const [cancel_after_count, setCancelAfterCount] = React.useState(3);
const [cancel_after_count_type, setCancelAfterCountType] = React.useState(3);

const [enable_reactive_suspend_after, setEnableReactiveSuspendAfter] = React.useState(false);
const [reactive_suspend_after, setReactiveSuspendAfter] = React.useState(-2);


const [sendEmailAfterCancelToCommercial, setSendEmailAfterCancelToCommercial] = React.useState(true);
const [sendEmailAfterSuspendToCommercial, setSendEmailAfterSuspendToCommercial] = React.useState(true);
const [sendEmailAfterReactToCommercial, setSendEmailAfterReactToCommercial] = React.useState(true);


const [sendEmailAfterSuspendToCustomEmailMark, setSendEmailAfterSuspendToCustomEmailMark] = React.useState(false);
const [sendEmailAfterSuspendToCustomEmail, setSendEmailAfterSuspendlToCustomEmail] = React.useState('');

const [sendEmailAfterCancelToCustomEmailMark, setSendEmailAfterCancelToCustomEmailMark] = React.useState(false);
const [sendEmailAfterCancelToCustomEmail, setSendEmailAfterCancelToCustomEmail] = React.useState('');

const [saving, setSaving] = React.useState(false);
const [loading, setLoading] = React.useState(true);


useEffect(() => {
  getData();
}, []);

const getData = async () => {
  setLoading(true);
   let response = await getApi("/invoice/signature/get/config",{
   });
   if(response){
    response.triggers.forEach((item) => {

      if(item.type=='suspend_after_overdue'){
        setSuspendAfterMark(item.enabled);
        setSuspendAfterCountType(item.suspend_after_count_type);
        setSuspendAfterCount(item.suspend_after_count);
      }else if(item.type=='reactive_suspend_overdue'){
        setEnableReactiveSuspendAfter(item.enabled);
        setReactiveSuspendAfter(item.reactive_suspend_after);
      }else if(item.type=='send_mail_after_suspend'){
        setSendEmailAfterSuspendToCommercial(item.enabled);
      }else if(item.type=='send_mail_after_cancel'){
        setSendEmailAfterCancelToCommercial(item.enabled);
      }else if(item.type=='send_mail_after_reactive'){
        setSendEmailAfterReactToCommercial(item.enabled);
      }
      
     });

   }
   setLoading(false);
   
}
  

const handleSubmit = async () => {
  setSaving(true);

   let response = await postApi("/invoice/signature/set/config",{
    suspend_after_mark:suspend_after_mark,
    suspend_after_count:suspend_after_count,
    suspend_after_count_type:suspend_after_count_type,

    cancel_after_mark:cancel_after_mark,
    cancel_after_count:cancel_after_count,
    cancel_after_count_type:cancel_after_count_type,
    
    enable_reactive_suspend_after:enable_reactive_suspend_after,
    reactive_suspend_after:reactive_suspend_after,

    sendEmailAfterCancelToCommercial:sendEmailAfterCancelToCommercial,
    sendEmailAfterSuspendToCommercial:sendEmailAfterSuspendToCommercial,
    sendEmailAfterReactiveToCommercial:sendEmailAfterReactToCommercial,

    sendEmailAfterSuspendToCustomEmailMark:sendEmailAfterSuspendToCustomEmailMark,
    sendEmailAfterSuspendToCustomEmail:sendEmailAfterSuspendToCustomEmail,

    sendEmailAfterCancelToCustomEmailMark:sendEmailAfterCancelToCustomEmailMark,
    sendEmailAfterCancelToCustomEmail:sendEmailAfterCancelToCustomEmail,

  });
 
  response_run(response);
  setSaving(false);

};

const response_run=(response)=>{
  if(response.success){
    alert("Fluxo alterados com sucesso.");
  }else  if(response.message){
    if(response.message=='This action is unauthorized.'){
      alert("Você não tem permissão para isso.")
      setSaving(false)
      return ;
    }
  }
  else{

    if(response.suspend_after_mark){
      alert(response.suspend_after_mark.toString())
    }else if(response.suspend_after_count){
      alert(response.suspend_after_count.toString())
    }else if(response.suspend_after_count_type){
      alert(response.suspend_after_count_type.toString())
    }else if(response.cancel_after_mark){
      alert(response.cancel_after_mark.toString())
    }else if(response.cancel_after_count){
      alert(response.cancel_after_count.toString())
    }else if(response.cancel_after_count_type){
      alert(response.cancel_after_count_type.toString())
    }else if(response.enable_reactive_suspend_after){
      alert(response.enable_reactive_suspend_after.toString())
    }else if(response.reactive_suspend_after){
      alert(response.reactive_suspend_after.toString())
    }else if(response.sendEmailAfterCancelToCommercial){
      alert(response.sendEmailAfterCancelToCommercial.toString())
    }else if(response.sendEmailAfterSuspendToCommercial){
      alert(response.sendEmailAfterSuspendToCommercial.toString())
    }else if(response.sendEmailAfterSuspendToCustomEmailMark){
      alert(response.sendEmailAfterSuspendToCustomEmailMark.toString())
    }else if(response.sendEmailAfterSuspendToCustomEmail){
      alert(response.sendEmailAfterSuspendToCustomEmail.toString())
    }else if(response.sendEmailAfterCancelToCustomEmailMark){
      alert(response.sendEmailAfterCancelToCustomEmailMark.toString())
    }else if(response.sendEmailAfterCancelToCustomEmail){
      alert(response.sendEmailAfterCancelToCustomEmail.toString())
    }else if(response.sendEmailAfterReactToCommercial){
      alert(response.sendEmailAfterReactToCommercial.toString())
    }else{
      alert( response.error)
    }
    setSaving(false)
  }
}



  return (
    <React.Fragment>
      {loading &&
           <Grid item>
           <Box sx={{ display: 'flex' }}>
             <CircularProgress />
           </Box>
        </Grid>
      }
      {!loading &&
        <React.Fragment>

      <Typography variant="h6" fontFamily="sans-serif"  gutterBottom display="inline" >
        Crie comandos de acordo com a situação das assinaturas
      </Typography>
      <Divider my={2} />
      
      <Typography gutterBottom display="inline">
      <Checkbox checked={suspend_after_mark==true} value={suspend_after_mark}
      onClick={e=> setSuspendAfterMark(!suspend_after_mark)} />
      Suspender contrato após <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={suspend_after_count}
          size="small"
          onChange={e=> setSuspendAfterCount(e.target.value)}
        >
         { Array.from({ length: 30 }, (_, i) => i + 1).map((number) => (
          number==1?
          <MenuItem value={number}>{number} </MenuItem>
          :
          <MenuItem value={number}>{number} </MenuItem>
        ))} 
      </Select>
      
      <Select sx={{marginLeft:1}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={suspend_after_count_type}
          size="small"
          onChange={e=> setSuspendAfterCountType(e.target.value)}
        >
          <MenuItem value={1}>{suspend_after_count==1?"fatura":"faturas"}</MenuItem>
          <MenuItem value={2}>{suspend_after_count==1?"dias":"dias"}</MenuItem>
          <MenuItem value={3}>{suspend_after_count==1?"mês":"meses"}</MenuItem>
      </Select> em atraso
      </Typography>

      {/*
      
      <Divider my={2} />
      <Typography gutterBottom display="inline">
      <Checkbox defaultChecked={cancel_after_mark}
      onClick={e=> setCancelAfterMark(!cancel_after_mark)} />
      Cancelar contrato após <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={cancel_after_count}
          size="small"
          onChange={e=> setCancelAfterCount(e.target.value)}
        >
         { Array.from({ length: 30 }, (_, i) => i + 1).map((number) => (
          number==1?
          <MenuItem value={number}>{number} </MenuItem>
          :
          <MenuItem value={number}>{number} </MenuItem>
        ))} 
      </Select>
      
      <Select sx={{marginLeft:1}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={cancel_after_count_type}
          size="small"
          onChange={e=> setCancelAfterCountType(e.target.value)}
        >
          <MenuItem value={1}>{cancel_after_count==1?"fatura":"faturas"}</MenuItem>
          <MenuItem value={2}>{cancel_after_count==1?"dias":"dias"}</MenuItem>
          <MenuItem value={3}>{cancel_after_count==1?"mês":"meses"}</MenuItem>
      </Select> em atraso
      <br></br>
      <small>Após o cancelamento, a reativação deverá ser manual.</small>
      </Typography>
     
     */}
     
      <Divider my={2} />

  
    <Typography gutterBottom display="inline">
    <Checkbox checked={enable_reactive_suspend_after}
    onClick={e=> setEnableReactiveSuspendAfter(!enable_reactive_suspend_after)} />
     Reativar contrato suspenso por inadimplência se <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reactive_suspend_after}
          size="small"
          onChange={e=> setReactiveSuspendAfter(e.target.value)}
        >
        <MenuItem value={-1}>todas faturas forem pagas </MenuItem>
        <MenuItem value={-2}>não tiver faturas vencidas </MenuItem>
         { 
          Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
          number==1?
          <MenuItem value={number}>no máximo ter {number} fatura vencida</MenuItem>
          :
          <MenuItem value={number}>no máximo ter {number} faturas vencidas</MenuItem>
        ))
        } 
      </Select>  

    </Typography>

    <Divider my={2} />
    <Typography gutterBottom display="inline">
    Notificações para o setor comercial
    </Typography>

    <Divider my={2} />


    <Typography gutterBottom display="inline">
    <Checkbox value={sendEmailAfterSuspendToCommercial} checked={sendEmailAfterSuspendToCommercial}
    onClick={e=> setSendEmailAfterSuspendToCommercial(!sendEmailAfterSuspendToCommercial)} />
      Enviar e-mail para o comercial após o contrato ser suspensa.
    </Typography>

    
    <Divider my={2} />
    <Typography gutterBottom display="inline">
    <Checkbox value={sendEmailAfterCancelToCommercial} checked={sendEmailAfterCancelToCommercial}
    onClick={e=> setSendEmailAfterCancelToCommercial(!sendEmailAfterCancelToCommercial)} />
      Enviar e-mail para o comercial após o contrato ser cancelada.
    </Typography>
   
    <Divider my={2} />
    <Typography gutterBottom display="inline">
    <Checkbox value={sendEmailAfterReactToCommercial} checked={sendEmailAfterReactToCommercial}
    onClick={e=> setSendEmailAfterReactToCommercial(!sendEmailAfterReactToCommercial)} />
      Enviar e-mail para o comercial após o contrato ser reativado.
    </Typography>


     {/*
     
     <Divider my={2} />

     <Typography gutterBottom display="inline">
    <Checkbox defaultChecked={sendEmailAfterSuspendToCustomEmailMark}
        onChange={e=> setSendEmailAfterSuspendToCustomEmailMark(!sendEmailAfterSuspendToCustomEmailMark)}
      />
      Enviar e-mail para <TextField onChange={e=> setSendEmailAfterSuspendlToCustomEmail(e.target.value)} value={sendEmailAfterSuspendToCustomEmail} size="small" label="e-mail adicional" variant="outlined" /> após o contrato ser suspenso.
    </Typography>

   

    
    <Divider my={2} />
    <Typography gutterBottom display="inline">
    <Checkbox 
        defaultChecked={sendEmailAfterCancelToCustomEmailMark}
        onChange={e=> setSendEmailAfterCancelToCustomEmailMark(!sendEmailAfterCancelToCustomEmailMark)}
     />
      Enviar e-mail para <TextField onChange={e=> setSendEmailAfterCancelToCustomEmail(e.target.value)} value={sendEmailAfterCancelToCustomEmail} size="small" label="e-mail adicional" variant="outlined" /> após o contrato ser cancelada.
    </Typography>
     
     
     */}
 
    <Grid container justifyContent="space-between" sx={{marginTop:5}} >

      <Button variant="contained" disabled={saving==true} color="primary" mt={3} sx={{marginLeft:3}} onClick={e=> handleSubmit()} >
        Salvar informações
      </Button>
    </Grid>
    </React.Fragment>
      }
       
    </React.Fragment>
  );
}

export default SignatureConfigTriggerCreate;
